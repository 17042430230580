@import '@sumday/assets/lib/styles/all-variables';
@import '@sumday/assets/lib/styles/color-variables';
@import '@sumday/assets/lib/styles/grid-variables';
@import '@sumday/assets/lib/styles/variables';
@import '@sumday/assets/lib/styles/bourbon';
@import '@sumday/assets/lib/styles/_mixins';
@import '@sumday/assets/lib/styles/icon-variables';

$accent-gray: #79747e;
$divider:#909090;
$gbl-font:#403947;
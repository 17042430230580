@import 'variables';
body sumday-popup-modal .popup-modal .modal-body .warning {
  .btn-cancel {
    color: $red;
    border-color: $red;

    &:hover,
    &:focus {
      background-color: $red;
      color: $white;
    }
  }
}

sumday-select .select-input .selected {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:before {
    top: unset;
    transform: unset;
    margin-top: 0.5rem;
  }
}

.pending {
  font-style: italic;
}

.border-t {
  border-top: 1px solid $divider;
}

body {
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  color:$gbl-font;
}
div{
  color:$gbl-font;
}
table:not(.mat-calendar-table) {
  margin: 0;
}

.chart-subheader,
.chart-timespan-subheader {
  margin-bottom: 0;
  margin-top: 1rem;
}

.chart-timespan-subheader {
  margin-top: 0.5rem;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.separated-item-list > :not(:last-child)::after {
  content: '\00a0\00a0|\00a0\00a0';
  white-space: pre;
}

.de-emphasized {
  font-weight: 400;
  color: $gbl-font;
}

.pending-value {
  font-style: italic;
}

.disclaimer {
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin-top: 1.5rem;
  color:$gbl-font;
}

.hidden {
  display: none;
}

/* sumday UI double border fix */
.text-input:after {
  border: none;
}

@media screen {
  .print-only {
    display: none !important;
  }

  @media #{$breakpoint-less-than-desktop} {
    .desktop-only {
      display: none !important;
    }
  }

  @media #{$breakpoint-desktop} {
    .mobile-only {
      display: none !important;
    }

    sumday-text.half-input {
      .text-input {
        width: 50%;
      }

      sumday-tooltip:not(.inline) {
        right: 50%;
      }
    }
  }
}

@media print {
  html {
    font-size: 46.875%;
  }

  app-notifications {
    display: none;
  }

  .screen-only {
    display: none !important;
  }
}
table {
  &.accordion-table {
    margin-bottom: 0;

    tbody tr:last-of-type {
      td {
        border-bottom: 0;
      }
    }
  }
}
.summary-sub-row > * {
  padding-left: 2rem;
}

.border-b-none {
  border-bottom: none !important;
}

.border-t-none {
  border-top: none !important;
}

.padding-t-none:first-of-type {
  padding-top: 0 !important;
}

.sentence-case {
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}

button:not(.mat-button-base) {
  &.btn-icon.btn-large {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;

    &:before {
      font-size: 2rem;
    }

    @media #{$breakpoint-less-than-desktop} {
      width: 3.5rem;
      height: 3.5rem;

      &:before {
        font-size: 1.4rem;
      }
    }
  }
}

body sumday-popup-modal .popup-modal .modal-body {
  min-height: unset;
}

body sumday-popup-modal .popup-modal .modal-body .fund-overflow {
  overflow: hidden;
}

body sumday-popup-modal .popup-modal .modal-header {
  padding: 0 1rem;
}

.primerica-icon-remove-circle {
  background-image: url('../assets/svgs/icon-remove-circle.svg');
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 1.4rem;
  min-height: 1.4rem;
}

.search-result-table {
  height: 40rem;
}

@media #{$breakpoint-greater-than-mobile} {
  .label {
    white-space: nowrap;
  }
}

div.tooltip-bubble {
  .copy {
    white-space: normal;
  }
}

//loading indicator
.loading-container-wrapper {
  position: relative;
  height: 100%;
  app-loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    backface-visibility: hidden;
  }
}

.favorite {
  width: 2.2rem;
  fill: gold;
  margin-right: 0.5rem;
  cursor: pointer;
}

.link-cursor{
  cursor: pointer;
}

span{
  color:$gbl-font
}
p{
  color:$gbl-font
}
h2{
  color:$gbl-font
}
sumday-validations .validation  {
  div {
    .text{
      color:$red;
    }
  }
  span{
    color:$red;
  }
}

.validation {
  div {
    color: $red;

    .text {
      color: inherit;
    }
  }

  span {
    color: inherit;
  }
}

.toast-custom-class {
  margin-top: env(safe-area-inset-top);
  font-size: 14px;
}
$data-copied-color: #e5f1f6;

sumday-select {
  // Data copied highlight
  &.data-copied {
    .select-input {
      .selected {
        background-color: $data-copied-color;
      }
    }
  }

  // sumday-select used on tile headers
  &.select-no-border {
    border: transparent;
    outline: transparent;
    display: inline-block;

    .select-input {
      border: transparent;

      .selected {
        border: transparent;
        border-radius: var(--base-border-radius);
      }
      div.selected.icon-down-caret {
        border: transparent;
      }
      .select-item {
        sumday-select-item {
          line-height: 0;
        }
      }
    }
    .select-list {
      border-radius: var(--base-border-radius);
      border-top: solid 1px $light-plum;
    }

    button:not(.no-default-styles):not(.mat-button-base) {
      &.select-control {
        background-color: initial;
        border: initial;
        border-radius: initial;

        &:hover,
        &:focus {
          background-color: initial;
          border-color: initial;
        }
      }
    }
  }

  .truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

app-radio-group {
  &.data-copied {
    li {
      &.selected {
        background-color: $data-copied-color;
      }
    }
  }
}

sumday-text {
  &.data-copied {
    .text-input {
      input,
      .prefix {
        background-color: $data-copied-color;
      }
    }
  }

  // global upper case
  input[type='text'] {
    text-transform: uppercase;
  }

  textarea {
    text-transform: uppercase;
  }
}

.validation.server:not(.form):before {
  content: none;
}

//buttons
button {
  &:not(.mat-button-base).btn-label-offset.btn-pill {
    margin-top: 3.5rem;
  }
}

.warning-message {
  color: $orange;
}

.upper-case {
  text-transform: uppercase;
}

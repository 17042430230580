@import '@sumday/assets/index';
@import '@sumday/components/index';
@import 'variables';

$tile-spacing: grid-space(1.5);
$tile-neg-spacing: grid-space(-1.5);

@media print {
  sumday-select {
    border: transparent;
    outline: transparent;
    display: inline-block;

    .select-input {
      border: transparent;

      div.selected.icon-down-caret {
        border: transparent;
        &::before {
          display: none;
        }
      }
    }
  }

  sumday-chart {
    display: none;
  }

  .text-input {
    width: auto;
  }

  sumday-popup-modal {
    position: absolute;

    .popup-modal {
      visibility: visible !important;
      overflow: visible !important;
    }
  }
}

sumday-notification {
  .notification {
    button.btn-icon {
      position: absolute;
    }

    .content {
      width: 95%;
      padding-left: 2rem;
    }
  }
}

// temporary fix. it should be component property instead
sumday-validations {
  &.sumday-validations-single {
    sumday-validation-error + sumday-validation-error {
      display: none;
    }
  }
}

// temporary fix for negative spacing in tile headers. It is fixed sumday components and should be removed after upgrade
.tile {
  .head {
    margin: ($tile-neg-spacing) 0 ($tile-spacing);
  }
  .foot {
    margin: ($tile-spacing) 0 ($tile-neg-spacing);
  }
}

p {
  color: $gbl-font;
  font-weight: 400;
}

// summary block
.summary-block {
  color: $gbl-font;
  font-weight: 400;
  &.summary-block-lg {
    .summary-row {
      padding: 3rem 0;

      &:first-of-type {
        padding: 2rem 0;
      }
    }
  }
}

sumday-uploader {
  div.uploader {
    div.text {
      max-width: calc(100% - 128px);
      ul.files {
        li {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

sumday-popup-modal {
  z-index: 900;
}

.summary-row-with-validation {
  flex-wrap: wrap;
  .validation {
    flex-basis: 100%;
    text-align: left;
  }
}

@media #{$breakpoint-mobile} {
  sumday-popup-modal {
    .modal-header {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .modal-body {
      padding-top: 50px;
    }
  }
}
sumday-radio {
  .toggle label {
    color:$gbl-font;
  }
  .toggle label .toggle-mark {
    border: 1px solid $gbl-font;
  }
}
sumday-checkbox { 
  .toggle label {
  color:$gbl-font;
  }
  .toggle label .toggle-mark {
    border: 1px solid $gbl-font;
  }
} 
sumday-checklist{
  .toggle {
    color:$gbl-font;
    }
    .toggle label .toggle-mark {
      border: 1px solid $gbl-font; 
    }
}

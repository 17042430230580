@import 'variables';

.mat-autocomplete-panel:not([class*='mat-elevation-z']) {
  box-shadow: $drop-shadow;
  border-radius: 0 0 3px 3px;
  border: solid 1px $light-plum;
  .mat-option {
    color: var(--form-control-text-color);
    font-family: var(--base-font-family);
    font-size: 1.6rem;
    line-height: 2rem;
    height: 44px;
    &:hover,
    &.mat-active {
      color: #fff;
      background: var(--form-control-focus-color);
    }

    &.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
      &:hover {
        color: #fff;
        background: var(--form-control-focus-color);
      }
    }
    + .mat-option {
      border-top: solid 1px $divider;
    }
  }
}

.mat-menu-content {
  // box-shadow: $drop-shadow;
  // border-radius: 0 0 3px 3px;
  // border: solid 1px $light-plum;

  // border: solid 1px #efeef0;
  // box-shadow: 0 2px 4px 0 rgb(218 218 218 / 50%);

  border: 1px solid $divider;
  border-radius: 3px;
  box-shadow: $drop-shadow;
}

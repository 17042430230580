@import "variables";

p {
  a {
    &.regular-link {
      color: $action-color;
      text-decoration: none;
      transition: color $base-duration $base-timing;

      &:active,
      &:focus,
      &:hover {
        color: shade($action-color, 25%);
      }

      &:focus {
        outline: 1px solid shade($action-color, 25%);
        outline-offset: 2px;
      }
    }
  }
}
